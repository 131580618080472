<template>
  <div class="">
    <v-card>
      <v-dialog v-model="dialogZip" max-width="600px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want create link to zip for this match?<br />
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeZip">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="zipMatchConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        dense
        :headers="outerHeaders"
        :items="matches"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :items-per-page="20"
        item-key="id"
        show-expand
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar id="toolbar">
            <v-toolbar-title>
              <span class="px-3">Active Matches - {{ now }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu top :close-on-click="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>

              <v-list rounded color="primary">
                <v-list-item @click="expandAll()">
                  <v-list-item-title color="primary">Expand</v-list-item-title>
                </v-list-item>
                <v-list-item @click="collapseAll()">
                  <v-list-item-title color="primary"
                    >Collapse</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="reloadPage">
                  <v-list-item-title>Refresh</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr>
            <td></td>
            <td class="text- d-none d-md-table-cell">{{ item.id }}</td>
            <td class="text-center">{{ item.datetime }}</td>
            <td class="text-center">{{ item.descr }}</td>
            <td class="text-center">
              <v-chip :color="item.datacolor">{{ item.ts }}</v-chip>
            </td>
            <td class="text-center">
              <v-icon small>{{ `mdi-thumb-${item.active}` }}</v-icon>
            </td>
            <td class="text-center">
              <v-icon
                v-if="item.ziplink != null && item.ziplink.startsWith('http')"
                class="mr-2"
                @click="startDownload(item.ziplink)"
                >mdi-cloud-download</v-icon
              >
              <span class="text-center" v-else>{{ item.ziplink }}</span>
            </td>
            <td class="text-center">
              <v-icon class="mr-2" @click="expand(!isExpanded)">
                {{ isExpanded ? "mdi-magnify-minus" : "mdi-magnify-plus" }}
              </v-icon>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text icon v-bind="attrs" v-on="on">
                    <v-icon class="mr-2" @click="zipMatch(item)">
                      mdi-folder-zip-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Create file for download</span>
              </v-tooltip>
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="outerHeaders.length">
            <v-data-table
              :headers="innerHeaders"
              :items="item.rows"
              item-key="id"
              class="elevation-1"
              hide-default-footer
            >
              <!-- I should be able to hide headers and have the parent row checkbox act like the select all headers' checkbox -->
              <template v-slot:item="props">
                <tr>
                  <td>{{ props.item.revision }}</td>
                  <td>{{ props.item.name }}</td>
                  <td class="text-xs-right">{{ props.item.ts }}</td>
                  <td class="text-xs-right">{{ props.item.cnt }}</td>
                </tr>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
export default {
  name: "MatchesDatatable",
  data() {
    return {
      expanded: [],
      singleExpand: false,
      matches: [],
      loading: true,
      options: {},
      dialogZip: false,
      editedIndex: -1,
      editedItem: {},
      editedSubIndex: -1,
      editedSubItem: {},
      outerHeaders: [
        {
          text: "Id",
          value: "id",
          align: " d-none d-md-table-cell",
          sortable: false,
        },
        { text: "Date", align: "center", value: "datetime" },
        { text: "Description", align: "center", value: "descr" },
        { text: "Last Ts", align: "center", value: "ts" },
        { text: "Valid", align: "center", value: "active" },
        { text: "Link", align: "center", value: "active" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      innerHeaders: [
        { text: "Revision", value: "revision", class: "blue lighten-2" },
        { text: "Name", value: "name", class: "blue lighten-2" },
        { text: "Last Ts", value: "ts", class: "blue lighten-2" },
        { text: "Count", value: "cnt", class: "blue lighten-2" },
      ],
      refreshTimer: "",
      now: "",
    };
  },
  watch: {
    options: {
      handler() {
        this.readDataFromAPI();
      },
      dialogZip(val) {
        val || this.closeZip();
      },
    },
    deep: true,
  },
  computed: {
    userid() {
      return this.$store.state.user.user.id;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
  },
  methods: {
    zipMatch(item) {
      this.editedIndex = this.matches.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogZip = true;
    },
    zipMatchConfirm() {
      // Recupera la lista di tutti i container per i match attivi
      const param = {
        matchId: this.editedItem.id,
        restartFlag: "N",
        containerApiUrl: process.env.VUE_APP_CONTAINER_API_URL,
        localApiUrl: process.env.VUE_APP_API_URL,
        debugFlag: "N",
      };

      axios
        .post(process.env.VUE_APP_API_URL + "/console/zipandlink", param)
        .then((response) => {
          alert("Launched. Please wait for link");
        })
        .catch((err) => {
          alert(err);
        });
      this.closeZip();
    },
    closeZip() {
      this.dialogZip = false;
    },
    showContainerLog(subRowData) {
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
          width=1024,height=768`;
      open(
        "/containerlog/" + subRowData.name,
        subRowData.name + " Log",
        params
      );
    },

    expandAll: function() {
      console.log("All expanded.");
      this.$data.expanded = this.$data.matches;
    },
    collapseAll: function() {
      console.log("All collapsed.");
      this.$data.expanded = [];
    },
    async readDataFromAPI() {
      this.now = new Date().toLocaleString();
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      // Recupera la lista di tutti i container per i match attivi
      axios
        .get(process.env.VUE_APP_API_URL + "/console/checkpostdatamatch", {
          params: {
            matchid: -1,
            dockerApiUrl: process.env.VUE_APP_DOCKER_API_URL,
            localApiUrl: process.env.VUE_APP_API_URL,
          },
        })
        .then((response) => {
          this.loading = false;
          // Legge in ordine di match e crea la struttura per master-details
          let queues = response.data;
          this.matches = [];
          let match = {};
          let matchRows = [];
          let prevMatchId = -1;
          let matchValid = false;
          let lastTs = "";
          let totCount = 0;
          let minDeltaSec = 9999;
          for (const item of queues) {
            if (item.matchId != prevMatchId) {
              if (prevMatchId >= 0) {
                match["rows"] = matchRows.sort(function(a, b) {
                  return (
                    a.revision.localeCompare(b.revision) ||
                    a.name.localeCompare(b.name)
                  );
                });
                if (lastTs != "") {
                  const cvtDate = new Date(lastTs);
                  match["ts"] = cvtDate.toLocaleString().substring(11, 20);
                }
                match["active"] =
                  minDeltaSec > 900 && totCount > 269 ? "up" : "down";
                match["datacolor"] =
                  minDeltaSec > 900 && totCount > 269 //15 minuti
                    ? "green"
                    : totCount > 30
                    ? "orange"
                    : "red";
                this.matches.push(match);
              }
              match = {
                id: item.matchId,
              };
              matchRows = [];
              prevMatchId = item.matchId;
              matchValid = false;
              lastTs = "";
              totCount = 0;
            }
            // Dettaglio
            if (item.matchDatetime) {
              const cvtDate = new Date(item.matchDatetime);
              match["datetime"] = cvtDate.toLocaleString();
              match["descr"] = item.matchDescr;
              match["optaId"] = item.matchOptaId;
              match["ziplink"] = item.ziplink;
            }

            if (item.queueName != null) {
              let rowTs = "";
              if (item.queueTs != null) {
                const cvtQueueDate = new Date(item.queueTs);
                rowTs = cvtQueueDate.toLocaleString().substring(11, 20);
              }
              matchRows.push({
                name: item.queueName,
                ts: rowTs,
                cnt: item.queueCount,
                revision: item.revision,
              });
              if (item.deltaSec < minDeltaSec) minDeltaSec = item.deltaSec;
              if (lastTs == "" || lastTs > item.queueTs) lastTs = item.queueTs;
              totCount += item.queueCount;
            }
          }
          // Ultimo
          match["rows"] = matchRows;
          const cvtDate = new Date(lastTs);
          if (lastTs != "") {
            const cvtDate = new Date(lastTs);
            match["ts"] = cvtDate.toLocaleString().substring(11, 20);
          }
          match["active"] = minDeltaSec > 900 && totCount > 269 ? "up" : "down";
          match["datacolor"] =
            minDeltaSec > 900 && totCount > 269 //15 minuti
              ? "green"
              : totCount > 30
              ? "orange"
              : "red";
          this.matches.push(match);

          //console.log(this.matches);

          //this.totalPassengers = response.data.totalPassengers;
          //this.numberOfPages = response.data.totalPages;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(row) {
      //console.log(row.postId);
      //this.$emit("setPost", row.postId, row.hometeam, row.awayteam);
    },
    reloadPage() {
      window.location.reload();
    },
    startDownload(url) {
      window.open(url, "_blank").focus();
    },
  },
  mounted() {},
  created() {
    this.readDataFromAPI();
    this.refreshTimer = setInterval(this.readDataFromAPI, 5000);
  },
  destroyed() {
    clearInterval(this.refreshTimer);
  },
};
</script>
